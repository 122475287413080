
.user-profile-page{
  width: 450px;
  height: 80%;
  background-color: white;
  opacity: 1;
  position: absolute;
  left: 4%;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
 
 }
 .user-profile-page .profile{
     margin-top: 50px;
 }
 #uploadIcon{
     width: 20px;
     height: 20px;
 }
 #showIcons{
     width: 25px;
     height: 20px;
 }
 #showIcon{
     width: 15px;
     height: 15px;
 }
 #hide {
     display: none;
   }
   .editBoxHeading{
     display: flex;
     justify-content: space-between;
     padding: 20px;
     color: white;
     font-weight: 900;
     background-color: #28b3f3;
     font-size: 20px;
   }
   .editBoxHeading:first-child{
     cursor: pointer;
     
   }
   .editingBox{
     border: none;
   }
   .editBox{
     width: 29%;
     height: 40%;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
     background-color: white;
     opacity: 1;
     position: absolute;
     top: 8%;
     left: 47%;
   }
   .editBoxContent{
     width: 90%;
     height: 40%;
     border: 1px solid black;
     position: absolute;
     top: 37%;
     left: 5%;
   }
   .editChatChanges{
     display: flex; 
     position: absolute;
     top: 80%;
     left: 70%;
     justify-content: space-evenly;
   }
   .editSave{
     color: white;
     font-weight: 700;
     background-color: #40b9f5;
     border: none;
     padding:5px 10px;
     border-radius: 4px;
   }
   .replyBack{
    display:flex;
    background-color: #40b9f5;
    color: white;
    width: 200px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    font-weight: 800;
    height: 20px;
   }
   .replyMessage{
     display: flex;
     flex-direction: column;
   }
   .replyChatMsgs{
    display: flex;
    flex-direction: column;
    background-color: #a2ddf8;
    padding: 4px;
    border-radius: 5px;
   }
   .showCount{
    color: white;
    background-color: #44c0fa;
    border-radius: 50%;
    width: 22px;
    height:22px;
    font-weight: 900;
    text-align: center;
    margin-left: 100px;
   }